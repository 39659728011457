<template>
  <svg
    class="absolute animate-step-spin"
    viewBox="0 0 48 48"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="opacity-50"
      d="M28.9214 36.049L32.9819 31.9877L39.9343 42.0065L35.873 46.067L28.9214 36.049Z"
    />
    <path
      d="M22.5346 48H16.7922L18.9604 35.9998H24.7027L22.5346 48Z"
      class="opacity-20"
    />
    <path d="M29.0397 12.0002H23.2966L25.4648 0H31.2079L29.0397 12.0002Z" />
    <path
      class="opacity-10"
      d="M5.99365 39.9343L1.93237 35.873L11.9511 28.9214L16.0116 32.9819L5.99365 39.9343Z"
    />
    <path
      d="M36.0491 19.0778L31.9878 15.0173L42.0065 8.06567L46.067 12.1262L36.0491 19.0778Z"
    />
    <path d="M0 22.5344V16.7921L12.0002 18.9603V24.7026L0 22.5344Z" />
    <path
      d="M35.9998 29.0397V23.2966L47.9999 25.4648V31.2079L35.9998 29.0397Z"
    />
    <path
      d="M8.06567 5.99358L12.1262 1.93231L19.0778 11.951L15.0173 16.0115L8.06567 5.99358Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'LoadingIcon',
  data: () => ({}),
  computed: {},
  methods: {}
}
</script>
